import { useMemo, useRef, useState,memo, useEffect } from "react";
import moment from "moment";
import CommonTable from '../../../CommonComponents/RessourcesDisplay/ReactTable/CommonTable'
import ActionBoutonView from "../../../CommonComponents/RessourcesDisplay/ActionButtonViewAllCase";
import ActionButtonReport from "../../../CommonComponents/RessourcesDisplay/ActionButtonReport";
import ActionBouton from "../../../CommonComponents/RessourcesDisplay/ActionBouton";
import { useSelector } from "react-redux";
import ReportStatus from "../../ReportStatus";
import { FormCheck, Dropdown, ButtonGroup } from "react-bootstrap";
import SendAetDropdown from "../../../Export/SendAetDropdown";
import apis from "../../../../services/apis";
const PatientStudyTable=({
    studies,
    rowEventsStudies,
    rowStyle,
    onDeletePatient,
    onDeleteStudy,
    setSelectedStudies,
    onModify,
    onDelete,
    refresh,
    hiddenRemoveRow,
    openLabelModal,
})=>{
    const roles = useSelector((state) => state?.PadiMedical?.roles);
    const [selected,setSelected] = useState({
      root: [],
      sub: [],
      mapper:{},
    })
    const [aets,setAets]=useState([]);

    useEffect(()=>{
       apis.aets.getAets()
       .then(setAets)
       .catch(console.log)
    },[])
        
    const columns=useMemo(()=>[
        { 
        id:"selected",
        accessor: "selected",
        show:roles.main_table_checkbox||false,
        Cell: ({ row }) => {
          let ID=row?.original?.ID;
          return <FormCheck checked={selected.mapper[ID]} onChange={()=>{
            if(ID && !selected.sub.includes(ID)){
              selected.sub.push(ID)
              selected.mapper[ID]=true;
            }else{
              let index=selected.sub.findIndex(str=>ID)
              if(index!==-1){
                selected.sub.splice(index,1);
                delete selected.mapper[ID]
              }
            }
            setSelected(prev=>({...prev,sub:selected.sub,mapper:selected.mapper}))
            setSelectedStudies(getSelectedRessources())
          }} />
        },
        
      },
        { Header: "No",accessor: "No",sort:true},
        {
          id:"ReportStatus",
        Header: "Report Status",
        accessor:"ReportStatus", 
        show:roles.main_table_status,
          
        Cell: ({ row }) => {
        return <ReportStatus status={row.values.ReportStatus} />;
        }
        },
        {
        id:"Date",
        Header: "Date",
        accessor:"Date",
        show:roles.main_table_date,
        Cell:({row})=>(row.values.Time?moment(row.values.Date).format('DD/MM/YYYY'):null),
        sort:true, 
        },
        {id:"Time",
         Header: "Time",
         accessor:"Time",
         show:roles.main_table_time,
         Cell:({row})=>(row.values.Time?<p style={{width:70}}>{moment(row.values.Time).format('hh:mm A')}</p>:null),
         sort:true,
        },
        { 
         id:"Patient_Name",
         Header: "Patient Name",
         accessor:"PName",
         show:roles.main_table_patient_name,
         sort:true,
        },
        {
        id:"PatientID",
        Header: "Patient ID",
        show:roles.main_table_patient_id,
         Cell: ({ row }) => {
            return <p>{row?.original?.PatientMainDicomTags?.PatientID}</p>;
          }        
        },
        {
        id:"Accession",
        Header: "Accession Number",
        show:roles.main_table_accession,
         Cell: ({ row }) => {
            return <p>{row?.original?.MainDicomTags?.AccessionNumber}</p>;
          }     
        },
        {
        id:"Description",
        Header: "Study Description",
        accessor:'StudyDescription',
        show:roles.main_table_description,
        sort:true,
        },
        {
        id:"Gender", 
        Header: "Gender",
        show:roles.main_table_gender,
        Cell: ({ row }) => {
            return <p>{row?.original?.PatientMainDicomTags?.PatientSex}</p>;
        } 
        },
        {
        id:"PatientDOB", 
        Header: "Dob",
        show:roles.main_table_dob,
        Cell: ({ row }) => {
            let dobDate=row?.original?.PatientMainDicomTags?.PatientBirthDate;
            let formatedStr=dobDate?moment(dobDate, 'YYYYMMDD').format('DD/MM/YYYY'):null;
            return <p>{formatedStr}</p>;
        } 
        },
        {
        id:"PatientAge", 
        Header: "Patient Age",
        show:roles.main_table_age,
        Cell: ({ row }) => {
            let dobDate=row?.original?.PatientMainDicomTags?.PatientBirthDate;
            let formatedStr=dobDate?moment(dobDate, 'YYYYMMDD').fromNow().replace('years ago','Yr'):null;
            return <p>{formatedStr}</p>;
        } 
        },
        {
          id: "select",
          Header: "Select",
          show:roles.main_table_selectBtn,
          editable: false,
          Cell: ({ row }) => {
            return (
              <ActionBouton
              level="studies"
              orthancID={row.original.ID}
              StudyInstanceUID={row.original.MainDicomTags.StudyInstanceUID}
              onDelete={onDeleteStudy}
              //row={row}
              refresh={refresh}
              pname={row.original.PatientMainDicomTags.PatientName}
              pid={row.original.PatientMainDicomTags.PatientID}
              StudyDescription={row.original.MainDicomTags.StudyDescription}
              openLabelModal={openLabelModal}
   
              />
            );
          },
        },
        {
          id: "view",
          Header: "View",
          show:roles.main_table_viewBtn,
          Cell: ({ row }) => {
            return (
              <ActionBoutonView
                role={roles}
                //tukar link - osimis viewer
                StudyInstanceUID={row.original.MainDicomTags.StudyInstanceUID}
                wsi_link={
                  "https://dataverse031sobrisvr.padimedical.com/wsi/app/index.html?series=" +
                  row.original.ID  //For rishab to adds on - add SeriesOrthancID
                }
                osimis_link={
                  "https://dataverse031sobrisvr.padimedical.com/osimis-viewer/app/index.html?study=" +
                  row.original.ID
                }
                OhifLink={"/viewer-ohif/viewer/" + row.original.MainDicomTags.StudyInstanceUID}
                radiant={
                  "radiant://?n=pstv&v=0020000D&v=%22" +
                  row.original.MainDicomTags.StudyInstanceUID
                }
                osirix={
                  "osirix://?methodName=downloadURL&URL=https://dataverse031sobrisvr.padimedical.com/studies/" +
                  row.original.ID +
                  "/archive"
                }
                downloadzip={
                  "https://dataverse031sobrisvr.padimedical.com/studies/" +
                  row.original.ID +
                  "/archive"
                }
              />
            );
          },
        },
        {
          id: "report",
          Header: "Metadata",
          show:roles.main_table_reportBtn,
          Cell: ({ row }) => {
            return (
              <ActionButtonReport
                pname={row.original.PatientMainDicomTags.PatientName}
                pid={row.original.PatientMainDicomTags.PatientID}
                accessor={row.values.accessor}
                StudyOrthancID={row.original.ID}
                StudyInstanceUID={row.original.MainDicomTags.StudyInstanceUID}
                description={{
                  StudyDescription: row.original.MainDicomTags.StudyDescription,
                  StudyDate: row.original.MainDicomTags.StudyDate,
                }}
                createOrviewLink={"/report/create/" + row.original.ID}
                viewLink={"/report/view/" + row.original.ID}
                requestLink={"/report/request/" + row.original.ID}
                addendun={"/report/addendun/" +row.original.ID}
                reqAdvImagin={
                  "/report/request-advance-imagin/" + row.original.ID
                }
              />
            );
          },
        },
        {
          id: "select-patient",
          Header: "Study",
          show:roles.main_table_patient_select,
          editable: false,
          Cell: ({ row }) => {
            return (
              <ActionBouton
              level="patients"
              btnLable={'Study'}
              impClass='patient-btn-select'
              hideOsimisViewer={null}
              orthancID={row.original.ParentPatient}
              onDelete={onDelete}
              onModify={onModify}
              row={row.values.raw}
              refresh={refresh}
              />
            );
          },
        },
        {
          id:"send_to",
          Header: "Send To",
          show:roles.can_transfer,
          Cell:({row})=>{
            return (<Dropdown as={ButtonGroup} autoClose="outside" className="mt-2">
            <Dropdown.Item>
              <SendAetDropdown aets={aets} exportIds={[row.original.ID]} />
            </Dropdown.Item>
          </Dropdown>)
          }
        },
        {id:"Detail",
        Header: "Detail", 
        show:roles.main_table_detail,
        Cell: ({ row }) => {
          return <button className="btn" onClick={()=>{
            rowEventsStudies([row?.original?.ID])
            document.querySelector('.series-area').scrollIntoView()
          }}>Click</button>;
        }
      },
    ],[onDeletePatient,
       onDeleteStudy,
       onModify,
       refresh,
       roles,
    //    hiddenAccessionNumber,
    //    hiddenActionBouton,
       hiddenRemoveRow,
      openLabelModal,
      aets])
    

    const getSelectedRessources=()=>{
      return {
          selectedPatients:[],
          selectedStudies: selected.sub,
      };
  }

    const data = useMemo(
        () =>
          studies.map((element, index) => {
            let Date=element?.MainDicomTags?.StudyDate;
            let Time=element?.MainDicomTags?.StudyTime;
            if(Date&&Time){
              Date=moment(Date+Time, 'YYYYMMDDHHmmss').format();
              Time=Date;
            }else{
              Date=Date?moment(Date, 'YYYYMMDD').format():null;
              Time=Time?moment(Time, 'HHmmss').format():null;
            }
            return {
               ...element, 
               No: index + 1 ,
               Date:Date,
               Time:Time,
               PName:element?.PatientMainDicomTags?.PatientName?.toUpperCase(),
               StudyDescription:element?.MainDicomTags?.StudyDescription
            };
          }),
        [studies]
      );

    // useEffect(()=>{
    //   if(data.length){
    //     const Options = {
    //       method: "POST",
    //       headers: {
    //         Accept: "application/json",
    //         "Content-Type": "application/json; charset=utf-8",
    //       },
    //       body: JSON.stringify({ ids: data.map(obj=>obj.ID) }),
    //     };
    //     fetch("/api/report-status-by-ids", Options)
    //       .then((res) => res.json())
    //       .then((res) => setStatus(res[0]));
    //   }
    // },[data])
return(
 <CommonTable tableData={data} columns={columns} pagination={true}  />
)
}
export default memo(PatientStudyTable);